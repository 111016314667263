// extracted by mini-css-extract-plugin
export var colorBlue = "w3PZv";
export var colorBrick = "JPtRl";
export var colorGold = "HP7a6";
export var colorGreen = "HRjxv";
export var colorLilac = "Xbgay";
export var colorOcean = "_0oxOi";
export var colorOrange = "fnWHz";
export var colorPeach = "KTa63";
export var colorPurple = "Dg4LA";
export var contentList = "_6sFhq";
export var more = "XLwfZ";
export var typeColumns = "MB4iO";
export var typeFull = "YEmzf";