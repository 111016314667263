// extracted by mini-css-extract-plugin
export var button = "X-qXA";
export var colorBlue = "XGq2g";
export var colorBrick = "EGlEp";
export var colorGold = "iYM6O";
export var colorGreen = "vZnRW";
export var colorLilac = "_6FY0Z";
export var colorOcean = "DoKGm";
export var colorOrange = "fF6Iy";
export var colorPeach = "_1pDKt";
export var colorPurple = "pILJg";