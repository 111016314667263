// extracted by mini-css-extract-plugin
export var boxContent = "_2Wr7j";
export var boxTitle = "_17AQW";
export var button = "vMAOo";
export var colorBlue = "hh2F6";
export var colorBrick = "hHWO4";
export var colorGold = "uVkEF";
export var colorGreen = "JJafd";
export var colorLilac = "j6x7i";
export var colorOcean = "tT1lW";
export var colorOrange = "XHpPQ";
export var colorPeach = "pcV2p";
export var colorPurple = "Aw+PT";
export var content = "vWA28";
export var hasImage = "OgACG";
export var image = "ACXfN";
export var imageContent = "HEBtn";
export var typeFull = "MBCTt";
export var typeSmall = "JsSZV";