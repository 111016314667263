// extracted by mini-css-extract-plugin
export var alignLeft = "rA6Vx";
export var alignRight = "Z61x2";
export var colorBlue = "Tbukc";
export var colorGold = "_5d8KS";
export var colorGreen = "enAxF";
export var colorLilac = "mu5-i";
export var colorOcean = "pDAas";
export var colorOrange = "et6JM";
export var colorPeach = "k1NY0";
export var colorPurple = "_1UTCd";
export var colorViolet = "lOjJw";
export var sectionTitle = "LLdmK";
export var sectionTitleContainer = "_3Db04";